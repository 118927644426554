import * as React from 'react';
import { connect } from 'react-redux';
import {
    Collapse,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Input,
    Nav,
    Modal,
    ModalHeader,
    ModalBody,
    Button
} from 'reactstrap';
import { Card } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import '../style/navbar/NavMenu.css';
//import imgFile from '../image/f1-new-logo.png';
import Logo from '../image/logo.png';
import Swal from 'sweetalert2';
import { parseJwt, checkExp, getEnv } from '../modules/Common';
import { ApplicationState } from '../store';

import * as AppState from '../store/AppState';
import * as Models from '../models/AppState';

import * as MasterCompanyStore from '../store/company/MasterCompany';
import * as MasterCompanyModels from '../models/company/MasterCompany';

import * as AdminDetailStore from '../store/admin/AdminDetail';
import * as AdminDetailModels from '../models/admin/MasterAdmin';

import { getLIFFData } from '../data/LineConnect/repositories/LineConnect.repository';
import LineConnectOnboardModal from '../common/LineConnectOnboardModal';
import LineConnectPreparationModal from '../common/LineConnectPreparationModal';

var imgFile = require('../image/f1-new-logo.png');

export interface optionValue {
    value: string;
    label: string;
}

export interface userDetailList {
    id: string;
    sid: string;
    role_access: string;
    company_code: string;
}

var closeTab;

interface States {
    checkUser: any;
    isOpen: boolean;
    showLineConnectModalType: 'preparation' | 'onboard' | 'none';
    version: string;
    user: string;
    isHideAlert: boolean;
    dropdownOpen: boolean;
    dropdownSidOpen: boolean;
    option: Array<optionValue>;
    value: string;
    pathName: string;
    urlDisableMenu: any;
    checkDisableMenu: boolean;
    checkInitialpath: boolean;
    checkReadOnlyMenu: boolean;
    urlReadOnlyMenu: any;
    checkTypeUser: boolean;
    checkInitialSelectedCompany: boolean;
    company_list: Array<optionValue>;
    urlComonCompany: Array<string>;
    checkRenderComapany: boolean;
    checkInitialCompany: boolean;
    userDetailList: Array<userDetailList>;
    userRoleAccess: string;
    liffId: string;
}

class NavMenu extends React.Component<any, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
            showLineConnectModalType: 'none',
            checkUser: '',
            version: '',
            user: '',
            isHideAlert: false,
            dropdownOpen: false,
            dropdownSidOpen: false,
            option: [
                {
                    value: '1mHUSXNKeGnm3yMz4k9vDoDBA3b',
                    label: 'บริษัท ศิลาสานนท์ จำกัด'
                },
                {
                    value: '1mHUXA5gy4XC58NNwXG5Pv5jhXs',
                    label: 'บริษัท ศิลาไทยสงวน (2540) จำกัด'
                },
                {
                    value: '1s421NbVxBwtM1jpjp7eDh5x0sR',
                    label: 'บริษัท สยามอุตสาหกรรมวัสดุทนไฟ จำกัด'
                },
                {
                    value: '1s41kgyAnv4dfqxdRXzDVIEf7XJ',
                    label: 'บริษัท ปูนซิเมนต์ไทย จำกัด'
                },
                {
                    value: '1tRwkyq7ehP3pXS6ZLHiDmyhmlG',
                    label: 'บริษัท คิวมิกซ์ซัพพลาย จำกัด'
                },
                {
                    value: '1tRwb4XQ3WeYDg4Xl1vblmGM4Fj',
                    label: 'บริษัท อีโค่ แพลนท์ เซอร์วิสเซส จำกัด'
                },
                {
                    value: '1s41rStqmc1RMtOtQTPLAdH9hMF',
                    label: 'บริษัท เอสซีไอ อีโค่ เซอร์วิสเซส จำกัด'
                },
                { value: '1JeqtQlfd6fuDpsFtUjp01mOR98', label: 'FocusoneLink' }
            ],
            value: '?',
            pathName: '',
            urlDisableMenu: [
                '/workflowinbox',
                '/workflowdashboard',
                '/MasterEmployee',
                '/workflow-inbox-detail',
                '/workflowlogbuffer',
                '/createUserAdmin'
            ],
            checkDisableMenu: true,
            checkInitialpath: true,
            checkReadOnlyMenu: false,
            urlReadOnlyMenu: [
                '/addpositionemployee',
                '/addauthoritygroup',
                '/addworkflow'
            ],
            checkTypeUser: false,
            checkInitialSelectedCompany: true,
            company_list: [],
            urlComonCompany: ['/conditionmaster'],
            checkRenderComapany: false,
            checkInitialCompany: true,
            userDetailList: [],
            userRoleAccess: '',
            liffId: ''
        };
    }

    componentDidMount() {
        var ls: any = localStorage.getItem('WF_APPLICATION');
        if (!ls) {
            return;
        }

        var jls: any = JSON.parse(ls);
        var access_token = parseJwt(jls.access_token);
        //var extra = JSON.parse(access_token)
        // console.log(access_token);

        //if (extra) {
        //    this.setState({ user: jls.user_id })
        //}

        var ls: any = localStorage.getItem('WF_APPLICATION');
        var jls: any = JSON.parse(ls);
        var access_token = parseJwt(jls.access_token);
        var extra = JSON.parse(access_token.extra);

        const pathName = window.location.pathname;
        const isOnInboxOrReportDetail =
            pathName.includes('workflow-inbox-overview')
            || pathName.includes('workflow-inbox-detail')
            || pathName.includes('workflow-report-overview')

        checkExp(
            access_token,
            !isOnInboxOrReportDetail
        );

        var userId: string = '';
        var checkUser: string = '';

        if (extra.first_name) {
            checkUser = extra.first_name + ' ' + extra.last_name;
            userId = extra.citizen_id;
        } else if (extra.citizen_id) {
            userId = extra.citizen_id;
            checkUser = extra.citizen_id;
        } else if (extra.username) {
            userId = extra.username;
            checkUser = extra.username;
        }

        this.setState({ user: userId, checkUser: checkUser });

        this.props.requestGetMasterCompany(true, 'GET', '', 'active');
        this.props.updateEnv(true, 'GET');
        this.props.requestGetAdminDetail(true, 'GET', userId);

        if (userId == '') {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่พบ user ใน token',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน'
            }).then(result => {
                getEnv();
            });
        }

        fetch('/v1/version')
            .then(res => res.json())
            .then(
                result => {
                    this.setState({
                        version: result.message
                    });
                },
                error => { }
            );

        fetch('/v1/liff/get')
            .then(res => res.json())
            .then(
                result => {
                    this.setState({
                        liffId: result.LIFF_ID
                    });
                },
                error => { }
            );

        //fetch("/v2/configuration/admin?citizen_id=" + userId, {
        //    method: 'GET',
        //    headers: {
        //        'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
        //    },
        //})
        //    .then((res) => res)
        //    .then(
        //        (result) => {
        //            console.log("adminnnnnnnnnnnnnnnnn")
        //            console.log(result)
        //            if (result.ok) {
        //                result.json().then(data => {
        //                    console.log(data)
        //                    if (data.total_count != 0) {
        //                        //this.setState({
        //                        //    checkTypeUser: true
        //                        //});
        //                        var userDetailList_: Array<userDetailList> = []
        //                        for (var i = 0; i < data.total_count; i++) {
        //                            var userDetail_: userDetailList = {
        //                                id: data.result_list[i].id,
        //                                sid: data.result_list[i].sid,
        //                                role_access: data.result_list[i].role_access,
        //                                company_code: data.result_list[i].company_code,
        //                            }
        //                            userDetailList_.push(userDetail_)
        //                        }

        //                        this.setState({
        //                            userDetailList: userDetailList_,
        //                            checkTypeUser: true
        //                        })
        //                    } else {
        //                        //this.setState({
        //                        //    typeUser : "user"
        //                        //})
        //                    }
        //                })
        //            } else {
        //                result.json().then(data => {
        //                    if (data.message == "No such host is known." || data.message == "An invalid request URI was provided. The request URI must either be an absolute URI or BaseAddress must be set.") {
        //                        Swal.fire({
        //                            title: 'เกิดข้อผิดพลาด',
        //                            text: 'ไม่สามารถเชื่อมต่อกับ server ได้ในขณะนี้',
        //                            icon: 'error',
        //                            showCancelButton: false,
        //                            confirmButtonColor: '#3085d6',
        //                            confirmButtonText: 'ยืนยัน',
        //                        }).then((result) => { window.location.reload() })
        //                    }
        //                })
        //            }
        //        },
        //        (error) => {
        //            this.setState({
        //                checkTypeUser: false
        //            });
        //        }
        //    )

        if (userId != '') {
            fetch('/v1/configuration/MasterEmployee?empid=' + userId, {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: 'Bearer ' + ls
                }
            })
                .then(res => res.json())
                .then(
                    result => {
                        var data = result.total_count;
                        if (data == 0) {
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'ไม่พบ user นี้ในระบบ',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'ยืนยัน'
                            }).then(result => {
                                getEnv();
                            });
                        }
                    },
                    error => { }
                );
        }
        var path = window.location.pathname;
        // console.log(path);
        if (path != '' && this.state.checkInitialpath) {
            for (let i = 0; i < this.state.urlDisableMenu.length; i++) {
                if (path == this.state.urlDisableMenu[i]) {
                    this.setState({
                        checkDisableMenu: false
                    });
                    break;
                } else {
                    this.setState({
                        checkDisableMenu: true
                    });
                }
            }
            this.setState({
                pathName: path,
                checkInitialpath: false
            });
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const {
            GetMasterCompany,
            isLoadGeAdminDetail,
            responseGetAdminDetail
        } = this.props;
        var path = window.location.pathname;
        // console.log('Navbar Company');
        // console.log('this.props.pathUrl : ' + this.props.pathUrl);
        // console.log('tthis.state.pathName : ' + this.state.pathName);
        // console.log('path : ' + path);
        // console.log('---------------------------------------------');
        if (this.props.pathUrl != this.state.pathName) {
            path = window.location.pathname;
            // console.log(path);
            if (path != '') {
                this.setState({
                    pathName: path
                });
                for (let i = 0; i < this.state.urlDisableMenu.length; i++) {
                    if (path == this.state.urlDisableMenu[i]) {
                        // console.log('set company disable');
                        this.setState({
                            checkDisableMenu: false
                        });
                        break;
                    } else {
                        this.setState({
                            checkDisableMenu: true
                        });
                    }
                }
            }
        }

        if (this.props.pathUrl != this.state.pathName) {
            this.setState({
                pathName: this.props.pathUrl
            });
            for (let j = 0; j < this.state.urlReadOnlyMenu.length; j++) {
                if (this.props.pathUrl != this.state.urlReadOnlyMenu[j]) {
                    this.setState({
                        checkReadOnlyMenu: false
                    });
                } else {
                    // console.log('set company disable');
                    this.setState({
                        checkReadOnlyMenu: true
                    });
                    break;
                }
            }
        }

        if (
            this.props.pathUrl != this.state.pathName &&
            this.state.company_list.length != 0 &&
            GetMasterCompany.isLoadGetMasterCompany &&
            GetMasterCompany.statusGetMasterCompany == 200 &&
            GetMasterCompany.responseGetMasterCompany.total_count != 0
        ) {
            // console.log('Condition Check Where ');
            this.setState({
                company_list: [],
                checkRenderComapany: false
            });
            var result_list_: Array<MasterCompanyModels.MasterCompanyList> =
                GetMasterCompany.responseGetMasterCompany.result_list;
            var adminDetail: Array<AdminDetailModels.userDetailList> =
                responseGetAdminDetail.result_list;
            var companyList_: Array<optionValue> = [];
            for (let i = 0; i < this.state.urlComonCompany.length; i++) {
                // console.log(
                //     this.props.pathUrl + ' : ' + this.state.urlComonCompany[i]
                // );
                if (this.props.pathUrl == this.state.urlComonCompany[i]) {
                    var data: any = {
                        value: '*',
                        label: 'common : ทั่วไป'
                    };
                    companyList_.push(data);
                }
            }

            if (adminDetail.length == 0) {
                this.setState({
                    checkTypeUser: false
                });
            } else {
                this.setState({
                    checkTypeUser: true
                });
            }

            var indexOfall = adminDetail.findIndex(i => i.company_code === '*');
            // console.log('indexOfall');
            // console.log(indexOfall);
            var companyList: Array<optionValue> = [];
            if (indexOfall <= -1) {
                console.log('find * in company_code');
                for (let i = 0; i < result_list_.length; i++) {
                    var data_find = adminDetail.findIndex(
                        item => item.sid === result_list_[i].sid
                    );
                    if (data_find > -1) {
                        var data: any = {
                            value: result_list_[i].sid,
                            label: result_list_[i].company_name
                        };
                        companyList.push(data);
                    }
                }
                // console.log('Condition Check1 companyList_ :');
                // console.log(companyList);
                this.setState({
                    company_list: companyList,
                    checkInitialCompany: false
                    //checkRenderComapany: true
                });
                setTimeout(this.setTime.bind(this), 500);
            } else {
                // console.log('* not in company_code');
                for (let i = 0; i < result_list_.length; i++) {
                    var data: any = {
                        value: result_list_[i].sid,
                        label: result_list_[i].company_name
                    };
                    companyList.push(data);
                }
                // console.log('Condition Check1 companyList_ :');
                // console.log(companyList);
                this.setState({
                    company_list: companyList,
                    checkInitialCompany: false
                    //checkRenderComapany: true
                });
                setTimeout(this.setTime.bind(this), 500);
            }

            var detail_: any = {};
            detail_ = adminDetail.find(
                item => item.sid === companyList[0].value
            );
            if (detail_ != null || detail_ != undefined) {
                this.setState({
                    userRoleAccess: detail_.role_access
                });
                // console.log('Role Access : ' + detail_.role_access);
            } else if (indexOfall > -1) {
                this.setState({
                    userRoleAccess: 'super_admin',
                    checkTypeUser: true
                });
            } else {
                this.setState({
                    userRoleAccess: '',
                    checkTypeUser: false
                });
            }
        }

        if (
            GetMasterCompany.isLoadGetMasterCompany &&
            GetMasterCompany.statusGetMasterCompany == 200 &&
            GetMasterCompany.responseGetMasterCompany.total_count != 0 &&
            this.state.checkInitialSelectedCompany
        ) {
            this.setState({
                value: GetMasterCompany.responseGetMasterCompany.result_list[0]
                    .sid
            });
            this.props.updateCompany(
                GetMasterCompany.responseGetMasterCompany.result_list[0].sid
            );
            this.setState({
                checkInitialSelectedCompany: false
            });
        }

        if (
            GetMasterCompany.isLoadGetMasterCompany &&
            GetMasterCompany.statusGetMasterCompany == 200 &&
            GetMasterCompany.responseGetMasterCompany.total_count != 0 &&
            this.state.company_list.length == 0 &&
            !this.state.checkRenderComapany &&
            this.state.checkInitialCompany &&
            isLoadGeAdminDetail
        ) {
            // console.log('Condition Check1');
            // console.log(responseGetAdminDetail.result_list);
            var result_list: Array<MasterCompanyModels.MasterCompanyList> =
                GetMasterCompany.responseGetMasterCompany.result_list;
            var adminDetail: Array<AdminDetailModels.userDetailList> =
                responseGetAdminDetail.result_list;
            var companyList_: Array<optionValue> = [];
            if (this.state.pathName != '') {
                for (let i = 0; i < this.state.urlComonCompany.length; i++) {
                    if (this.state.pathName == this.state.urlComonCompany[i]) {
                        var data: any = {
                            value: '*',
                            label: 'common : ทั่วไป'
                        };
                        companyList_.push(data);
                    }
                }

                if (!adminDetail) {
                    setTimeout(() => {
                        Swal.fire({
                            title: 'ไม่สำเร็จ!',
                            text: 'ไม่สามารถเชื่อมต่อกับ Server',
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false
                        }).then(result => {
                            window.location.reload();
                            //this.props.requestRejectWorkflowList(true, "CLEAR", "");
                            //this.refreshOnUpdate()
                            ////if (result.value) {
                            ////    if (this.state.checkurl == "No_URL") {
                            //this.props.requestApproveWorkflowAdmin(true, "CLEAR", "", "", "");
                            //this.refresh()
                            ////    }
                            ////    this.setState({
                            ////        checkapprove: true,
                            ////    })
                            ////    this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
                            ////    //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                            ////}
                        });
                    }, 500);
                    return;
                }

                if (adminDetail.length == 0) {
                    this.setState({
                        checkTypeUser: false
                    });
                } else {
                    this.setState({
                        checkTypeUser: true
                    });
                }

                var indexOfall = adminDetail.findIndex(
                    i => i.company_code === '*'
                );
                // console.log('indexOfall');
                // console.log(indexOfall);
                if (adminDetail.length != 0) {
                    if (indexOfall <= -1) {
                        // console.log('find * in company_code');
                        for (let i = 0; i < result_list.length; i++) {
                            var data_find = adminDetail.findIndex(
                                item => item.sid === result_list[i].sid
                            );
                            if (data_find > -1) {
                                var data: any = {
                                    value: result_list[i].sid,
                                    label: result_list[i].company_name
                                };
                                companyList_.push(data);
                            }
                        }
                        // console.log('Condition Check1 companyList_ :');
                        // console.log(companyList_);
                        this.setState({
                            company_list: companyList_,
                            checkInitialCompany: false
                            //checkRenderComapany: true
                        });
                        setTimeout(this.setTime.bind(this), 500);
                    } else {
                        // console.log('* not in company_code');
                        for (let i = 0; i < result_list.length; i++) {
                            var data: any = {
                                value: result_list[i].sid,
                                label: result_list[i].company_name
                            };
                            companyList_.push(data);
                        }
                        // console.log('Condition Check1 companyList_ :');
                        // console.log(companyList_);
                        this.setState({
                            company_list: companyList_,
                            checkInitialCompany: false
                            //checkRenderComapany: true
                        });
                        setTimeout(this.setTime.bind(this), 500);
                    }
                } else {
                    // console.log('user is not role access');
                    for (let i = 0; i < result_list.length; i++) {
                        var data: any = {
                            value: result_list[i].sid,
                            label: result_list[i].company_name
                        };
                        companyList_.push(data);
                    }
                    // console.log('Condition Check1 companyList_ :');
                    // console.log(companyList_);
                    this.setState({
                        company_list: companyList_,
                        checkInitialCompany: false
                        //checkRenderComapany: true
                    });
                    setTimeout(this.setTime.bind(this), 500);
                }

                var detail_: any = {};
                detail_ = adminDetail.find(
                    item => item.sid === companyList_[0].value
                );

                if (detail_ != null || detail_ != undefined) {
                    this.setState({
                        userRoleAccess: detail_.role_access
                    });
                    // console.log(
                    //     '[Debug] Role Access 1: ' + detail_.role_access
                    // );
                } else if (indexOfall > -1 && adminDetail.length > 0) {
                    // found * in company_code
                    this.setState({
                        userRoleAccess: adminDetail[0].role_access,
                        checkTypeUser: true
                    });
                    // console.log(
                    //     '[Debug] Role Access 2: ' + adminDetail[0].role_access
                    // );
                } else {
                    this.setState({
                        userRoleAccess: '',
                        checkTypeUser: false
                    });
                }
            }
        } else if (
            !GetMasterCompany.isLoadGetMasterCompany &&
            this.state.company_list.length != 0
        ) {
            this.setState({
                company_list: []
            });
        }
    }

    setTime = () => {
        this.setState({
            checkRenderComapany: true
        });
    };

    toggledropdown = () => {
        const { dropdownOpen } = this.state;
        this.setState({
            dropdownOpen: !dropdownOpen
        });
    };

    toggledropdownSid = () => {
        const { dropdownSidOpen } = this.state;
        this.setState({
            dropdownSidOpen: !dropdownSidOpen
        });
    };

    handleChangeCompany = event => {
        const { responseGetAdminDetail } = this.props;
        // console.log(event.target.value);
        this.setState({ value: event.target.value });

        var adminDetail: Array<AdminDetailModels.userDetailList> =
            responseGetAdminDetail.result_list;
        var detail_: any = {};
        detail_ = adminDetail.find(item => item.sid === event.target.value);
        var indexOfall = adminDetail.findIndex(i => i.company_code === '*');
        if (detail_ != null || detail_ != undefined) {
            this.setState({
                userRoleAccess: detail_.role_access
            });
            // console.log('Role Access : ' + detail_.role_access);
        } else if (indexOfall > -1) {
            this.setState({
                userRoleAccess: 'super_admin',
                checkTypeUser: true
            });
        } else {
            this.setState({
                userRoleAccess: '',
                checkTypeUser: false
            });
        }

        this.props.updateCompany(event.target.value);
    };

    toggleShowLineConnect = (type: States['showLineConnectModalType']) =>
        this.setState({ showLineConnectModalType: type });

    private toggleIsOpen = () => this.setState({ isOpen: !this.state.isOpen });

    public render() {
        const { showLineConnectModalType } = this.state;

        return (
            <header>
                <Navbar
                    className="sticky-top navbar-toggleable-sm border-bottom box-shadow bg-navbar mb-3 gap-2"
                    expand="lg"
                    light
                >
                    <NavbarBrand
                        className="d-flex align-items-center"
                        style={{ padding: '0' }}
                    >
                        <div className="trapezoid-logo">
                            {/*<img src={logo} style={{ width: '96px', height: '40px', cursor: 'pointer', marginTop: '4px;}} >*/}
                            <a href={'/workflowinbox'}>
                                <img
                                    src={imgFile}
                                    style={{
                                        marginTop: '4px',
                                        width: '96px',
                                        height: '40px'
                                    }}
                                    alt="file"
                                />
                            </a>
                            {/*< img src={'https://slsn-o2c-uat.thailanderp.com/images/f1-new-logo.png'} width="96px" height="40" style={{marginTop:'4px'}} />
                                <img src={imgFile} alt="file" width="25" height="25" />*/}
                        </div>
                        <div className="nav-brand-name text-nowrap">
                            <span className="hidden sm:inline">FocusOne</span>
                            {' '}
                            Workflow
                            <span
                                style={{ fontSize: '8pt', paddingLeft: '5px' }}
                            >
                                V {this.state.version}
                            </span>
                        </div>
                    </NavbarBrand>

                    <Button
                        tag={Link}
                        to="/workflowinbox"
                        color="primary"
                        size="sm"
                        className="px-4 ml-auto"
                    >
                        Inbox
                    </Button>
                    <Button
                        tag={Link}
                        to="/workflowdashboard"
                        color="primary"
                        size="sm"
                        className="px-4"
                    >
                        Report
                    </Button>

                    <NavbarToggler onClick={this.toggleIsOpen} />
                    <Collapse
                        className="flex-sm-row-reverse font-weight-bold text-right"
                        isOpen={this.state.isOpen}
                        navbar
                    >
                        <Nav
                            className="text-nowrap align-items-lg-center flex-grow justify-end"
                            navbar
                        >
                            {/*<NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                </NavItem> */}
                            {/* <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
                                </NavItem>  */}
                            {/* <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/useremployee">Employee</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/roleemployee">Role Employee</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/authoritygroup">Authority Group</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/workflow">Workflow Config</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/conditionmaster">Parameter Master</NavLink>
                                </NavItem> */}
                            {/* <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/typemaster">Type Master</NavLink>
                                </NavItem> */}
                            {this.state.checkDisableMenu && (
                                <NavItem className="mx-lg-0 mx-3">
                                    <Input
                                        type="select"
                                        name="select"
                                        className="form-control-sm"
                                        onChange={this.handleChangeCompany}
                                        defaultValue={this.props.company}
                                        disabled={this.state.checkReadOnlyMenu}
                                        value={this.props.company}
                                    >
                                        {/*{GetMasterCompany.isLoadGetMasterCompany && GetMasterCompany.statusGetMasterCompany == 200 && GetMasterCompany.responseGetMasterCompany.total_count != 0 && GetMasterCompany.responseGetMasterCompany.result_list.map((data: MasterCompanyModels.MasterCompanyList, index) => {*/}
                                        {this.state.checkRenderComapany &&
                                            this.state.company_list.length !=
                                            0 &&
                                            this.state.company_list.map(
                                                (data: optionValue, index) => {
                                                    return (
                                                        <option
                                                            value={data.value}
                                                        >
                                                            {data.label}
                                                        </option>
                                                    );
                                                }
                                            )}
                                    </Input>
                                </NavItem>
                            )}

                            {/* {
                                this.props.location.pathname.includes('workflow-inbox-detail')
                                && (
                                    <> */}
                            {/* <NavItem className="lg:block mx-lg-0 mx-3 bg-white shadow-md rounded-md px-4">
                                <NavLink
                                    tag={Link}
                                    className="text-dark py-1.5"
                                    to="/workflowinbox"
                                >
                                    Inbox
                                </NavLink>
                            </NavItem>

                            <NavItem className="lg:block mx-lg-0 mx-3 bg-white shadow-md rounded-md px-4">
                                <NavLink
                                    tag={Link}
                                    className="text-dark py-1.5"
                                    to="/workflowdashboard"
                                >
                                    Report
                                </NavLink>
                            </NavItem> */}

                            <li className="hidden lg:block h-[28px] w-[2px] rounded-md bg-[#414c5d]" />
                            {/* </>
                                )
                            } */}

                            {this.state.liffId && (
                                <NavItem className="mx-lg-0 mx-3">
                                    <img
                                        src="https://focusone-documents.s3.ap-southeast-1.amazonaws.com/line-icon/Line-Icon.png"
                                        onClick={() =>
                                            this.toggleShowLineConnect(
                                                'preparation'
                                            )
                                        }
                                        className="h-[35px] w-[35px] cursor-pointer"
                                    />
                                </NavItem>
                            )}

                            <NavItem className="mx-lg-0 mx-3">
                                <NavLink
                                    tag={Link}
                                    className="text-dark"
                                    to="/workflowinbox"
                                >
                                    {this.state.checkUser}
                                </NavLink>
                            </NavItem>

                            <Dropdown
                                nav
                                isOpen={this.state.dropdownOpen}
                                toggle={this.toggledropdown}
                            >
                                <DropdownToggle
                                    nav
                                    caret
                                    className="text-dark position-relative mx-lg-0 mx-3"
                                >
                                    Menu
                                </DropdownToggle>
                                <DropdownMenu
                                    style={{
                                        top: '35px',
                                        right: '1rem',
                                        left: 'unset',
                                        position: 'absolute'
                                    }}
                                >
                                    {this.state.checkTypeUser && (
                                        <div>
                                            <DropdownItem header>
                                                Workflow Config
                                            </DropdownItem>
                                            {/* <DropdownItem><Link to="/useremployee">Employee</Link></DropdownItem> */}
                                            {(this.state.userRoleAccess ===
                                                'super_admin' ||
                                                this.state.userRoleAccess ===
                                                'admin') && (
                                                    <DropdownItem>
                                                        <Link to="/workflowinboxadminsummary">
                                                            Workflow Report
                                                        </Link>
                                                    </DropdownItem>
                                                )}
                                            {this.state.userRoleAccess ===
                                                'super_admin' && (
                                                    <div>
                                                        {/*<DropdownItem><Link to="/MasterEmployee">Admin Config</Link></DropdownItem>*/}
                                                        <DropdownItem>
                                                            <Link to="/MasterEmployee">
                                                                Master Employee
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/roleemployee">
                                                                Role Employee
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/rolemasteremployee">
                                                                Role Master Employee
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/authoritygroup">
                                                                Authority Group
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/conditionmaster">
                                                                Parameter Master
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/callbackconfigmaster">
                                                                Callback Config
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/callbacklogstart">
                                                                Callback Stat
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/callbacklog">
                                                                Callback Log
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/workflow">
                                                                Workflow Config
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/createUserAdmin">
                                                                Workflow Config Add Admin
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/workflowinboxadminsummary">
                                                                Workflow Summary
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/workflowreport">
                                                                Workflow Dashboard
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/workflowinboxadmin">
                                                                Workflow Inbox
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/workflowlog">
                                                                Workflow Log
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/workflowlogbuffer">
                                                                Workflow Log Buffer
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/bufferstat">
                                                                Workflow Buffer Stat
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/workflow-email-log">
                                                                Workflow Email Log
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/workflowconditionconfigurationreport">
                                                                Workflow Condition
                                                                Configuration Report
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/workflowassignmentroutingreport">
                                                                Workflow Assignment
                                                                Routing Report
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/assignmentroutingconfig">
                                                                Workflow Assignment
                                                                Routing Config
                                                            </Link>
                                                        </DropdownItem>
                                                    </div>
                                                )}
                                            <DropdownItem divider />
                                            {/* <DropdownItem><Link to="/typemaster">Type Master</Link></DropdownItem> */}
                                        </div>
                                    )}
                                    {/* <DropdownItem header>Workflow Config</DropdownItem>
                                        <DropdownItem><Link to="/useremployee">Employee</Link></DropdownItem>
                                        <DropdownItem><Link to="/roleemployee">Role Employee</Link></DropdownItem>
                                        <DropdownItem><Link to="/authoritygroup">Authority Group</Link></DropdownItem>
                                        <DropdownItem><Link to="/workflow">Workflow Config</Link></DropdownItem>
                                        <DropdownItem><Link to="/conditionmaster">Parameter Master</Link></DropdownItem>
                                        <DropdownItem><Link to="/typemaster">Type Master</Link></DropdownItem>
                                        <DropdownItem><Link to="/addconfigmaster">Config Master</Link></DropdownItem>
                                        <DropdownItem divider /> */}
                                    <DropdownItem header>
                                        Workflow Working
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to="/workflowdashboard">
                                            Workflow Dashboard
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to="/workflowinbox">
                                            Workflow Inbox
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to="/createworkflow">
                                            Create Workflow
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to="/createworkflowwithwftype">
                                            Create Workflow <br />
                                            With Workflow Type
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Link to="/simulateworkflow">
                                            Simulate Workflow
                                        </Link>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <NavItem className="mx-lg-0 mx-3">
                                <NavLink
                                    tag={Link}
                                    className="text-dark"
                                    to="/logout"
                                >
                                    Back to Portal
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>

                <LineConnectPreparationModal
                    isOpen={showLineConnectModalType === 'preparation'}
                    onToggle={() => this.toggleShowLineConnect('none')}
                    onClickStartConnect={() =>
                        this.toggleShowLineConnect('onboard')
                    }
                />
                <LineConnectOnboardModal
                    isOpen={showLineConnectModalType === 'onboard'}
                    onToggle={() => this.toggleShowLineConnect('none')}
                />
            </header>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.appState,
        ...state.MasterCompany,
        ...state.adminDetail
    }),
    {
        ...AppState.actionCreators,
        ...MasterCompanyStore.actionCreators,
        ...AdminDetailStore.actionCreators
    }
)(withRouter(NavMenu));
