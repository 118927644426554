import axios from 'axios';
import { Input } from 'reactstrap';
import { FaPlusCircle, FaSearch } from 'react-icons/fa';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Button,
    Container,
    Row,
    Col,
    Form,
    ButtonGroup,
} from 'react-bootstrap';
import * as XLSX from 'xlsx';
import useLoader from 'hooks/useLoader';

import {
    MasterCompanyList,
    MasterCompanyResponse
} from 'models/company/MasterCompany';
import {
    getAssignmentMember,
    getAssignmentRouting
} from 'data/AssignmentRouting/repositories/AssignmentRouting.repository';
import {
    AssignmentRoutingMemberResponse,
    AssignmentRoutingParams,
    AssignmentRoutingResponse
} from 'data/AssignmentRouting/schemas/AssignmentRouting.schema';
import { ResponseList } from 'models/common/respone.model';
import { CreateEmployeeBodyAndResponse } from 'data/PositionEmployee/schemas/PositionEmployee.schema';
import { UpdateAssignmentRouting } from 'data/PositionEmployee/repositories/PositionEmployee.repository';
import { parseJwt } from 'helpers/decodeLocalStorage';
import Swal from 'sweetalert2';
import { fileURLToPath } from 'url';
import { getWorkflowInbox } from 'data/Workflow/repositories/Workflow.repository';
import AssignmentRoutingConfigDocModal from './components/AssignmentRoutingConfigDocModal';
import { WorkflowInbox } from 'models/workflow/WorkflowInbox';
import AssignmentRoutingTable from './components/AssignmentRoutingConfigTable';

function AssignmentRoutingConfig() {
    const loading = useLoader();

    const [filter, setFilter] = useState<'All' | 'Active' | 'InActive' | 'Warning' | 'Error'>('All');
    const [selectdAllCompany, setSelectdAllCompany] = useState<boolean>(true);
    const [isOpenDocumentWorkflowModal, setIsOpenDocumentWorkflowModal] = useState(false);
    const [companyMaster, setCompanyMater] = useState<MasterCompanyList[]>();
    const [assignmentRoutingParams, setAssignmentRoutingParams] = useState<AssignmentRoutingParams>({
        authgroup: '',
        empid: '',
        positioncode: '',
        sids: ['']
    });
    const [assignmentRoutingResponse, setAssignmentRoutingResponse] = useState<AssignmentRoutingResponse[]>();
    const [assignmentRoutingMapping, setAssignmentRoutingMapping] = useState<AssignmentRoutingResponse[]>();
    const [assignmentRoutingMember, setAssignmentRoutingMember] = useState<AssignmentRoutingMemberResponse[]>();
    const [workflowInboxResponse, setWorkflowInboxResponse] = useState<WorkflowInbox[]>();

    const company = useRef<string>('');
    const username = useRef<string>('');

    useEffect(() => {
        fetchCompanyMaster();

        var ls: any = localStorage.getItem('WF_APPLICATION');
        var jls: any = JSON.parse(ls);
        var access_token = parseJwt(jls.access_token);
        var extra = JSON.parse(access_token.extra);

        if (extra.citizen_id) {
            username.current = extra.citizen_id;
        } else if (extra.username) {
            username.current = extra.username;
        }
    }, []);

    const filteredReports = useMemo(() => {
        if (!assignmentRoutingResponse) {
            return undefined;
        }

        if (filter === 'All') {
            return assignmentRoutingResponse;
        }

        const memberMap = new Map<string, AssignmentRoutingMemberResponse>();

        assignmentRoutingMember?.forEach(member => {
            const key = `${member.sid}-${member.positioncode}-${member.positiongroupid}`;
            memberMap.set(key, member);
        });

        if (filter === 'Warning') {
            const filteredResponse = assignmentRoutingResponse.filter(report => {
                const key = `${report.sid}-${report.positioncode}-${report.authgroup}`;
                const member = memberMap.get(key);
                return member?.countmember == 1;
            });

            console.log(filteredResponse);

            return filteredResponse;
        }

        if (filter === 'Error') {
            const filteredResponse = assignmentRoutingResponse.filter(report => {
                const key = `${report.sid}-${report.positioncode}-${report.authgroup}`;
                return !memberMap.has(key);
            });

            console.log(filteredResponse);

            return filteredResponse;
        }

        const condition =
            filter === 'Active'
                ? (validToDate: Date) => validToDate >= new Date()
                : (validToDate: Date) => validToDate < new Date();

        return assignmentRoutingResponse.filter(report => {
            const validto = report.validto;
            const year = Number(validto.slice(0, 4));
            const month = Number(validto.slice(4, 6)) - 1;
            const day = Number(validto.slice(6, 8));

            const validtoDate = new Date(year, month, day);

            return condition(validtoDate);
        });
    }, [filter, assignmentRoutingResponse]);

    const filterButtons = useMemo(
        () => [
            {
                label: 'All',
                value: 'All',
                onClick: () => setFilter('All')
            },
            {
                label: 'Active',
                value: 'Active',
                onClick: () => setFilter('Active')
            },
            {
                label: 'In-Active',
                value: 'InActive',
                onClick: () => setFilter('InActive')
            },
            {
                label: 'Warning',
                value: 'Warning',
                onClick: () => setFilter('Warning')
            },
            {
                label: 'Error',
                value: 'Error',
                onClick: () => setFilter('Error')
            }
        ],
        []
    );

    const fetchCompanyMaster = async () => {
        const response = await axios.get<MasterCompanyResponse>(
            '/v1/company_master',
            {
                headers: {
                    Authorization:
                        'Bearer ' + localStorage.getItem('WF_APPLICATION')
                }
            }
        );
        setCompanyMater(response.data.result_list);
    };

    const handleSearchAssignment = async () => {
        try {
            await fetchAssignmentMember();
            await fetchAssignmentRouting();
        } catch {
            Swal.fire({
                icon: 'error',
                text: 'เกิดข้อผิดพลาดในการค้นหา กรุณาลองใหม่อีกครั้ง'
            })
        }
    }

    const fetchAssignmentRouting = async () => {
        try {
            loading.show();

            const [, response] = await getAssignmentRouting(assignmentRoutingParams);

            if (response) {
                setAssignmentRoutingResponse(response.result_list);
            }
        } finally {
            loading.hide();
        }
    };

    const fetchAssignmentMember = async () => {
        try {
            loading.show();

            const [, response] = await getAssignmentMember({
                sid: company.current,
                positioncode: assignmentRoutingParams.positioncode,
                authgroup: assignmentRoutingParams.authgroup
            });

            if (response) {
                setAssignmentRoutingMember(response);
            }
        } finally {
            loading.hide();
        }
    };
    const handleChangeSearchHelp = <K extends keyof AssignmentRoutingParams>(
        key: K,
        value: AssignmentRoutingParams[K]
    ) => {
        setAssignmentRoutingParams(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    // const handleChangeConfigAssignmentRouting1 = <
    //     K extends keyof AssignmentRoutingResponse
    // >(
    //     filteredIndex: number,
    //     key: K,
    //     value: AssignmentRoutingResponse[K]
    // ) => {
    //     if (!filteredReports) {
    //         return;
    //     }

    //     const reportToUpdate = filteredReports[filteredIndex];
    //     const originalIndex = assignmentRoutingResponse!.findIndex(
    //         report => report.sid === reportToUpdate.sid
    //     );

    //     if (originalIndex === -1) {
    //         return;
    //     }

    //     setAssignmentRoutingResponse(prevState => {
    //         const newState = [...prevState!];
    //         const data = newState[originalIndex];

    //         if (data) {
    //             newState[originalIndex] = {
    //                 ...data,
    //                 [key]: value
    //             };
    //         }

    //         return newState;
    //     });
    // };

    const handleChangeConfigAssignmentRouting = (
        assignmentToUpdate: AssignmentRoutingResponse,
        field: 'validfrom' | 'validto',
        value: string
    ) => {
        Swal.fire({
            icon: 'info',
            title: 'มีการแก้ไข User',
            html: `Position Code: ${assignmentToUpdate.positioncode} 
                <br/> User Id: ${assignmentToUpdate.empid} 
                <br/> ${value === '99991231' ? 'Active' : 'In Active'} เรียบร้อยแล้ว`
        });

        setAssignmentRoutingMapping(prevAssignments => {
            if (!prevAssignments || prevAssignments.length === 0) {
                return [{ ...assignmentToUpdate, [field]: value.trim() }];
            }

            let assignmentFound = false;

            const updatedAssignments = prevAssignments.map(assignment => {
                if (
                    assignment.positioncode ===
                    assignmentToUpdate.positioncode &&
                    assignment.emp_name === assignmentToUpdate.emp_name &&
                    assignment.authgroup === assignmentToUpdate.authgroup &&
                    assignment.sid === assignmentToUpdate.sid &&
                    assignment.sid_name === assignmentToUpdate.sid_name
                ) {
                    assignmentFound = true;
                    return {
                        ...assignment,
                        [field]: value.trim()
                    };
                }
                return assignment;
            });

            if (!assignmentFound) {
                updatedAssignments.push({
                    ...assignmentToUpdate,
                    [field]: value.trim()
                });
            }

            return updatedAssignments;
        });
    };

    const handleFetchWorkflowDocList = async (sid: string, user: string, docType: string) => {
        try {
            loading.show();

            const [, response] = await getWorkflowInbox({ app_id: sid, user_id: user });
            if (response && response.total_count > 0) {
                if (docType !== '') {
                    const type = docType.split('=')[1].trim();
                    const filterResponse = response.result_list.filter((value) => {
                        return value.docType.toLocaleLowerCase().includes(type.toLocaleLowerCase());
                    })

                    setIsOpenDocumentWorkflowModal(filterResponse.length > 0 ? true : false);
                    setWorkflowInboxResponse(filterResponse);
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'ไม่พบเอกสารคงค้างใน Document Type นี้'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'info',
                    title: 'ไม่พบเอกสารคงค้าง'
                })
            }
        } catch (ex) {
            Swal.fire({
                icon: 'error',
                title: 'ไม่สามารถค้นหาเอกสาร',
                text: `ไม่สามารถค้นหาเอกสารคงค้างของ User ${user} ได้`
            })
        } finally {
            loading.hide();
        }
    }

    const handleSubmitChangeData = async () => {
        try {
            const body: CreateEmployeeBodyAndResponse[] = [];

            if (assignmentRoutingMapping) {
                assignmentRoutingMapping.forEach(element => {
                    const data: CreateEmployeeBodyAndResponse = {
                        sid: element.sid,
                        emp_id: element.empid,
                        emp_name: element.emp_name,
                        position_code: element.positioncode,
                        position_name: element.position_condition,
                        valid_to: element.validto,
                        valid_from: element.validfrom,
                        is_delegate: '',
                        delegate_from_emp_id: ''
                    };

                    body.push(data);
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'กรุณาเลือกข้อมูลแก้ไข'
                });
            }
            const [, response] = await UpdateAssignmentRouting(body);

            if (response === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'แก้ไข เสร็จสิ้น',
                    preConfirm: async () => {
                        await handleSearchAssignment();
                    }
                });
            }
        } catch (ex) {
            throw ex;
        }
    };

    const ExportTemplate = async () => {
        try {
            loading.show();
            var date = new Date();
            var filename = 'AssignmentRoutingConfig' + date.getTime().toString() + '.xlsx';
            var Employee: any = [];

            if (filteredReports) {
                filteredReports.map(item => {
                    let data = {};
                    data['Company'] = item.sid_name;
                    data['Auth Group'] = item.authgroup;
                    data['Auth Group Name'] = item.authgroup_name;
                    data['Position Code'] = item.positioncode;
                    data['Position Condition'] = item.position_condition;
                    data['Emp Id'] = item.empid;
                    data['Emp Name'] = item.emp_name;
                    data['Email'] = item.email_address;
                    data['Valid From'] = item.validfrom;
                    data['Valid To'] = item.validto;
                    Employee.push(data);
                });
                var ws_Employee = XLSX.utils.json_to_sheet(Employee);
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws_Employee, 'AssignmentRoutingConfig');
                XLSX.writeFile(wb, filename);
            }
        } finally {
            loading.hide();
        }
    };

    return (
        <Container>
            <Row>
                <Col className="import-export-actions">
                    <div
                        className="trapezoid-head"
                        style={{ width: '250px' }}
                    >
                        <span>Assignment Routing Config</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mat-box">
                        <div
                            className="trapezoid trapezoid-purple"
                            style={{
                                width: '200px',
                                display: 'inline-block'
                            }}
                        >
                            <span>
                                <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                ></i>
                                Search menu
                            </span>
                        </div>
                        <div className="mat-box mb-5 rounded bg-white p-3 shadow">
                            <Row>
                                <Col md={5}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Company Name :</Form.Label>
                                        {/* <div>
                                            <input
                                                type="checkbox"
                                                checked={selectdAllCompany}
                                                onChange={e => {
                                                    e.persist();
                                                    handleChangeSearchHelp(
                                                        'sids',
                                                        ['']
                                                    );
                                                    setSelectdAllCompany(
                                                        prev => !prev
                                                    );
                                                    company.current = '';
                                                }}
                                            />
                                            <span className="ml-2">
                                                เลือกทั้งหมด
                                            </span>
                                        </div> */}
                                        <Input
                                            type="select"
                                            onChange={e => {
                                                e.persist();
                                                handleChangeSearchHelp('sids', [
                                                    e.target.value
                                                ]);
                                                company.current =
                                                    e.target.value;
                                            }}
                                        // disabled={selectdAllCompany}
                                        >
                                            <option selected>
                                                Please choose
                                            </option>
                                            {companyMaster &&
                                                companyMaster.map(
                                                    (value, index) => (
                                                        <option
                                                            key={index}
                                                            value={value.sid}
                                                        >
                                                            {value.company_name}
                                                        </option>
                                                    )
                                                )}
                                        </Input>
                                    </Form.Group>
                                </Col>
                                <Col
                                    md={2}
                                    className="flex items-end justify-center"
                                >
                                    <div className="text-center">
                                        <Button
                                            variant="outline-info"
                                            size="sm"
                                            onClick={handleSearchAssignment}
                                        >
                                            <FaSearch /> Search
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Auth Group :</Form.Label>
                                        <Input
                                            bsSize="sm"
                                            onChange={e => {
                                                e.persist();
                                                handleChangeSearchHelp(
                                                    'authgroup',
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Position Code :</Form.Label>
                                        <Input
                                            bsSize="sm"
                                            onChange={e => {
                                                e.persist();
                                                handleChangeSearchHelp(
                                                    'positioncode',
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={1}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Emp ID :</Form.Label>
                                        <Input
                                            bsSize="sm"
                                            onChange={e => {
                                                e.persist();
                                                handleChangeSearchHelp(
                                                    'empid',
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        {filteredReports && (
                            <React.Fragment>
                                <Row className="mt-3">
                                    <Col>
                                        <ButtonGroup size="sm">
                                            {filterButtons.map((filterButton, index) => (
                                                <Button
                                                    key={index}
                                                    variant={
                                                        filterButton.value !==
                                                            filter
                                                            ? 'outline-primary'
                                                            : undefined
                                                    }
                                                    onClick={
                                                        filterButton.onClick
                                                    }
                                                >
                                                    {filterButton.label}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </Col>
                                    <Col>
                                        <ButtonGroup size="sm">
                                            <Button
                                                variant={'outline-success'}
                                                onClick={handleSubmitChangeData}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant="outline-success"
                                                size="sm"
                                                className="ml-3"
                                                onClick={ExportTemplate}
                                            >
                                                <FaPlusCircle />
                                                &nbsp;Export Excel
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <AssignmentRoutingTable
                                            filteredReports={filteredReports}
                                            handleChangeConfigAssignmentRouting={handleChangeConfigAssignmentRouting}
                                            assignmentRoutingMapping={assignmentRoutingMapping}
                                            assignmentRoutingMember={assignmentRoutingMember}
                                            handleFetchWorkflowDocList={handleFetchWorkflowDocList}
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                    </div>
                </Col>
            </Row>
            <AssignmentRoutingConfigDocModal
                isOpen={isOpenDocumentWorkflowModal}
                toggle={() => setIsOpenDocumentWorkflowModal(false)}
                workflowDocumentDetail={workflowInboxResponse!}
                companyMaster={companyMaster!}
            />
        </Container>
    );
}

export default AssignmentRoutingConfig;
